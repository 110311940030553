<template>
  <v-dialog v-model="formDialog" max-width="40%">
    <v-card class="dialog-content">
      <v-card-title>
        <div class="color--grey-0 my-5">
          Definição de comissão específica por categoria
        </div>
      </v-card-title>
      <v-form ref="categoryForm" lazy-validation >
        <v-card-text>
          <div class="d-flex mb-10">
            <label class="left-content">Categoria*</label>
            <custom-select
              class="right-content"
              item-text="name"
              item-value="id"
              v-model="form.categoryId"
              :nofilter="true"
              :search="searchCategory"
              @focusout="validateCategory(form, categories)"
              :validation-rules="[validateCategoryValue]"
              validate-on-blur
              filled
              dense
              light
            />
          </div>
          <div class="d-flex">
            <label class="left-content">Tarifa de venda*</label>
            <v-text-field
              placeHolder="Digite o percentual"
              type="number"
              v-model="form.percentage"
              :rules="[validatePercentage]"
              validate-on-blur
              filled
              dense
              light
            />
          </div>
        </v-card-text>
      </v-form>
      <v-card-actions style="padding: 0 30px 30px 30px;">
        <v-spacer />
        <v-btn color="#07BEE7" @click="formCancel" outlined>Cancelar</v-btn>
        <v-btn color="#07BEE7" @click="onSubmitHandler" outlined>Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomSelect from '@/components/custom-select/CustomSelect';
import { listCategory } from '../../../service/category/category-service';

export default {
  name: 'CategoriesForm',
  components: {
    CustomSelect,
  },
  props: {
    categoryForm: { type: Object, default: null },
    types: { type: Array, default: () => [] },
    formCancel: { type: Function },
    formSubbmit: { type: Function },
    formDialog: { type: Boolean, default: false },
  },
  data: () => ({
    categories: null,
    form: {
      categoryId: null,
      name: '',
      percentage: 0,
    },
  }),
  watch: {
    categoryForm: {
      handler(newValue) {
        this.form = { ...newValue };
      },
    },
  },
  methods: {
    async searchCategory(param, filter, itemsPerPage) {
      const data = await listCategory(param, filter, itemsPerPage, 'name', 'ASC');
      this.categories = data.register;
      return data;
    },
    validatePercentage: (value) => {
      if (value) {
        return (value >= 0 && value <= 100) || 'Campo `Tarifa de venda` deve estar entre 0 e 100.';
      }
      return 'Campo `Tarifa de venda` é Obrigatório.';
    },
    validateCategoryValue: (value) => (!value ? 'Campo `Categoria` é obrigatório' : true),
    validateCategory: (form, categories) => {
      form.name = categories.find((category) => category.id === form.categoryId).name;
    },
    onSubmitHandler() {
      const { categoryForm } = this.$refs;
      const validated = categoryForm?.validate();
      if (!validated || this.editing) return;

      this.$emit('onSubmit', { ...this.form });
      this.form = {
        categoryId: null,
        name: '',
        percentage: 0,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
label.left-content {
  width: 20%;
  flex-shrink: 0;
}
</style>
