import { get, post, put, remove } from '@/service/api';
import remapSortDesc, { buildSortUrl, buildUrl } from '@/utils/service-utils';

export async function getCommissionRule(id) {
  const response = await get(`ui-integrator/commission-rule/${id}`);
  return response;
}

export async function createCommissionRule(form) {
  const response = await post('ui-integrator/commission-rule', form);
  return response;
}

export async function updateCommissionRule(id, form) {
  const response = await put(`ui-integrator/commission-rule/${id}`, form);
  return response;
}

export async function listCommissionRule(page, itemsPerPage, query, sortBy, orderBy) {
  const params = await buildUrl(page, itemsPerPage);
  const mapedOrder = remapSortDesc(orderBy);

  query = query.replaceAll(' and ', '&').replaceAll(' = ', '=').replaceAll('( ', '').replaceAll(')', '');
  query = query.replaceAll('!', '').replaceAll('initialDate=', 'submittedAt >=').replaceAll('finalDate =', 'submittedAt <=');

  const data = await get(`ui-integrator/commission-rule/fetch?${params}&filter=${query}&order_by=${sortBy}&sort_by=${mapedOrder}`);
  return data;
}

export async function deleteCommissionRule(id) {
  const response = await remove(`ui-integrator/commission-rule/${id}`);
  return response;
}
