<template>
  <v-container class="box-content pa-0 pl-4 pr-4 pt-4" fluid>
    <FormStepController
      :steps="steps"
      v-on:formSubbmit="formSubbmit"
      v-on:formCancel="formCancel"
      :blockSave="!this.$store.getters.isAdmin"
      :form="form"
      alertScreenTitle="cadastro de regras de comissões"
      title="Novas regras de comissões"
    >
      <InformationPeriodForm :validationRules="steps['InformationPeriodForm'].rules" :form="form" @validateRequired="validateRequired" />
      <CommissionsForm
        :validationRules="steps['CommissionsForm'].rules"
        title="COMISSÕES"
        :form="form"
        @validateRequired="validateRequired"
        v-on:deleteCategory="deleteCategory"
      />
    </FormStepController>
  </v-container>
</template>

<script>
import FormStepController from '@/components/template/form/FormStepController';
import InformationPeriodForm from '@/views/commissionRules/forms/InformationPeriodForm';
import CommissionsForm from '@/views/commissionRules/forms/CommissionsForm';
import { routerPush } from '@/service/kplaceRouter';
import notification from '@/service/notification';
import utils from '@/utils/utils';
import { createCommissionRule, getCommissionRule, updateCommissionRule } from '@/service/commission-rule/commission-rule-service';
import { fetchCategory } from '@/service/category/category-service';

export default {
  name: 'CommissionRuleForm',
  data: () => ({
    steps: {
      InformationPeriodForm: {
        step: 1,
        status: {},
        required: {
          titleRule: true,
          initialDateRule: true,
          finalDateRule: true,
        },
        rules: {
          titleRule: (value) => (value && value.length > 0) || 'Campo `Título da regra de comissão` é Obrigatório.',
          initialDateRule: (value) => (value && value.length > 0) || 'Campo `Data inicial` é Obrigatório.',
          finalDateRule: (value) => true,
        },
      },
      CommissionsForm: {
        step: 2,
        status: {},
        required: {
          commissionPercentageDefault: true,
        },
        rules: {
          commissionPercentageDefault: (value) => {
            if (value) {
              return (value >= 0 && value <= 100) || 'Campo `Tarifa de venda` deve estar entre 0 e 100.';
            }
            return 'Campo `Tarifa de venda` é Obrigatório.';
          },
          otherCardPercentage: (value) => (value >= 0 && value <= 100) || 'Campo `Cartões bandeirados` deve estar entre 0 e 100.',
          koerichCardPercentage: (value) => (value >= 0 && value <= 100) || 'Campo `Cartão Koerich` deve estar entre 0 e 100.',
        },
      },
    },
    form: {
      id: '',
      titleRule: '',
      commissionPercentageDefault: null,
      percentageByCategory: [],
      initialDateRule: '',
      finalDateRule: '',
      description: '',
      koerichCardPercentage: null,
      otherCardPercentage: null,
      immediatePaymentKoerichCard: false,
      immediatePaymentOtherCard: false,
    },
  }),
  components: {
    FormStepController,
    InformationPeriodForm,
    CommissionsForm,
  },

  created() {
    if (this.$route.params.id) {
      this.editing = true;
    }
    utils.setStepStatus(this);
    utils.setStepRequireds(this);
  },
  mounted() {
    if (this.$route.params.id) {
      this.getCommissionRule(this.$route.params.id);
    }
    this.steps.InformationPeriodForm.rules.finalDateRule = this.validateFinalDateRule;
  },
  methods: {
    async getCommissionRule(id) {
      try {
        const response = await getCommissionRule(id);
        if (response.data?.percentageByCategory.length > 0) {
          await this.fetchCategory(response.data.percentageByCategory);
        }
        if (response?.status !== 200 && response?.status !== 201) {
          notification(`${response?.data.message}`, 'error');
        } else {
          this.form = response.data;
          const splitFinalDateRule = this.form.finalDateRule.split(' ');
          this.form.finalDateRule = `${splitFinalDateRule[0]
            .split('/')
            .reverse()
            .join('-')} ${splitFinalDateRule[1]}`;
          const splitInitialDateRule = this.form.initialDateRule.split(' ');
          this.form.initialDateRule = `${splitInitialDateRule[0]
            .split('/')
            .reverse()
            .join('-')} ${splitInitialDateRule[1]}`;
        }
      } catch (error) {
        notification('Erro ao recuperar a regra de comissão', 'error');
      }
    },
    validateFinalDateRule(value) {
      if (!value) return 'Campo `Data final` é Obrigatório.';
      if (value && value.length > 0 && this.form.initialDateRule && this.form.initialDateRule.length > 0) {
        return new Date(value).getTime() > new Date(this.form.initialDateRule).getTime() || 'Campo `Data final` deve ser maior que o `Data inicial`.';
      }
      return true;
    },
    async formSubbmit() {
      try {
        let result = null;
        if (this.editing) {
          result = await updateCommissionRule(this.$route.params.id, this.form);
        } else {
          result = await createCommissionRule(this.form);
        }
        if (result.status >= 200 && result.status <= 300) {
          notification('As definições das regras de comissões foram salvas com sucesso.', 'success');
          routerPush('/commission-rule');
        } else {
          notification(`${result?.response?.data?.message}`, 'error');
        }
      } catch (error) {
        notification(`Erro ao salvar regra de comissão: ${error.response.data.message}`);
      }
    },
    formCancel() {
      routerPush('/commission-rule');
    },
    validateRequired(tab, field, value) {
      this.steps[tab].required[field] = !(this.steps[tab].rules[field](value) === true);
      utils.revalidate(this);
    },
    deleteCategory(index) {
      // eslint-disable-next-line no-unused-expressions
      this.form?.percentageByCategory?.splice(index, 1);
    },
    async fetchCategory(percentageByCategory) {
      try {
        const promises = percentageByCategory.map(async (item) => {
          const { categoryId } = item;
          const { register } = await fetchCategory(`( id = ${categoryId} )`, 200, 'name', 'ASC', false);
          if (register && register.length > 0) {
            item.name = register[0].name;
          }
        });
        await Promise.all(promises);
      } catch (error) {
        notification('Não foi possível obter o nome da categoria, Tente novamente!', 'error');
      }
    },
  },
};
</script>

<style>
.box-content {
  background-color: rgb(237, 237, 237);
  height: calc(100% - 80px);
  margin-top: 80px;
}
</style>
