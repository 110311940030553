export function mapperToCategoryRequest(formData) {
  const category = {
    id: formData.id,
    googleId: formData.googleId,
    boletoConfig: formData.boletoConfig,
    description: formData.description,
    discountsByPaymentType: formData.discountsByPaymentType,
    installmentConfig: formData.installmentConfig,
    indexing: formData.indexing,
    installmentConfigKoerich: formData.installmentConfigKoerich,
    maxAmountToSell: formData.maxAmountToSell,
    name: formData.name,
    parentId: formData.parentId,
    showDimensionTable: formData.showDimensionTable,
    stamps: formData.stamps,
    status: formData.status,
    updateAllFields: formData.updateAllFields,
  };

  category.priority = formData.priority.value ? formData.priority.value : formData.priority;
  // eslint-disable-next-line vue/max-len
  category.integrationPlatform = formData.integrationPlatform.value ? formData.integrationPlatform.value : formData.integrationPlatform;

  return category;
}

export default mapperToCategoryRequest;
