import {
  get, post, put, remove,
 } from '@/service/api';
import { mapperToCategoryRequest } from './category-mapper';
import notification from '@/service/notification';
import remapSortDesc, { buildSortUrl, buildUrl } from '@/utils/service-utils';

export async function getCategory(id, plataforma) {
  const data = await get(`ui-integrator/category/${id}?integrationPlatform=${plataforma}`);
  if (data.response !== undefined && data.response.status !== 200) {
    notification(Array.isArray(data.response.data) ? data.response.data[0].message : data.response.data.message, 'error');
  }
  return data;
}

export async function listCategory(param, filter, itemsPerPage, orderBy, sortBy, onlySon = false) {
  const { data } = await get(`ui-integrator/category/fetch?onlySon=${onlySon}&size=${itemsPerPage ? `${itemsPerPage}` : '1000'}&filter=${param ? `${filter} = !${param}` : ''}
  &order_by=${orderBy ? `${orderBy}` : ''}&sort_by=${sortBy ? `${sortBy}` : ''}`);
  if (data.response !== undefined && data.response.status !== 200) {
    notification(Array.isArray(data.response.data) ? data.response.data[0].message : data.response.data.message, 'error');
  }
  return data;
}

export async function fetchCategory(filter, itemsPerPage, orderBy, sortBy, onlySon) {
  const { data } = await get(`ui-integrator/category/fetch?onlySon=${onlySon}&size=${itemsPerPage ? `${itemsPerPage}` : '1000'}&filter=${filter}
  &order_by=${orderBy ? `${orderBy}` : ''}&sort_by=${sortBy ? `${sortBy}` : ''}`);
  if (data.response !== undefined && data.response.status !== 200) {
    notification(Array.isArray(data.response.data) ? data.response.data[0].message : data.response.data.message, 'error');
  }
  return data;
}

export async function createCategory(form) {
  let data = null;
  try {
    data = await post('ui-integrator/category/create', mapperToCategoryRequest(form));
    if (data.response !== undefined && data.response.status !== 200) {
      notification(Array.isArray(data.response.data) ? data.response.data[0].message : data.response.data.message, 'error');
    }
  } catch (error) {
    notification(`Erro ao criar categoria : ${error}`, 'error');
  }
  return data;
}

export async function updateCategory(form) {
  let data = null;
  try {
    form.updateAllFields = true;
    data = await put(`ui-integrator/category/${form.id}`, mapperToCategoryRequest(form));
    if (data.response !== undefined && data.response.status !== 200) {
      notification(Array.isArray(data.response.data) ? data.response.data[0].message : data.response.data.message, 'error');
    }
  } catch (error) {
    notification(`Erro ao atualizar categoria : ${error}`, 'error');
  }
  return data;
}

export async function listCategoryPage(page, itemsPerPage, query, sortBy, orderBy) {
  const params = await buildUrl(page, itemsPerPage);
  const mapedOrder = remapSortDesc(orderBy);
  const sortOptions = buildSortUrl(sortBy, mapedOrder);
  const data = await get(`ui-integrator/category/fetch?${params}&filter=${query}${sortOptions}`);
  return data;
}

export async function deleteCategory(id, integrationPlatform) {
  const data = await remove(`ui-integrator/category/${id}?integrationPlatform=${integrationPlatform}`);
  return data;
}
