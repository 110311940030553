<template>
  <FormContent :selected="selected" class="pa-6">
    <v-row class="margin-fix pa-3">
      <h3>Comissão geral</h3>
    </v-row>
    <v-row class="margin-fix pa-3">
      <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
        <TextFieldInput
          :disabled="!!form.id"
          input-type="number"
          label-width="40%"
          v-model="form.commissionPercentageDefault"
          label="Tarifa de venda*"
          placeHolder="Digite o percentual"
          :validationRules="[validationRules.commissionPercentageDefault]"
          @focusout="validate('commissionPercentageDefault')"
        />
      </v-col>
    </v-row>
    <v-row class="margin-fix pa-3">
      <v-col xl="8" lg="8" sm="8" md="8" class="pb-0"> <h3>Comissões por categoria</h3></v-col>
      <v-col xl="4" lg="4" sm="4" md="4" class="pb-0">
        <v-btn :disabled="!!form.id" color="#07BEE7" style="color: #FFFFFF; float: right" depressed @click="addCategoty()">
          + COMISSÃO POR CATEGORIA
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="margin-fix pa-3 mb-10">
      <v-col xl="12" lg="12" sm="12" md="12" class="pb-0" v-show="form.percentageByCategory && form.percentageByCategory.length > 0">
        <v-data-table :headers="header" :items="form.percentageByCategory" class="elevation-1 custom-table" hide-default-footer>
          <template v-slot:[`item.actions`]="{ item, index }">
            <v-icon color="primary" :disabled="!!form.id" @click="editCategory(item, index)">mdi-pencil</v-icon>
            <v-icon color="red" :disabled="!!form.id" @click="deleteCategory(index)">mdi-delete</v-icon>
          </template>
          <template v-slot:no-data>
            <div class="pt-10 pb-10">
              Nenhum resultado encontrado
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <div v-show="!form.percentageByCategory || form.percentageByCategory.length == 0">
      <span style="color: rgb(108, 117, 125); caret-color: rgb(108, 117, 125);">
        <p>
          Ainda não existem comissões específicas por categorias cadastradas. <br />
          Essa informação é opcional para a definição das regras de comissões.
        </p>
      </span>
    </div>
    <CategoriesForm :categoryForm="categoryForm" :formDialog="formDialog" :formCancel="formCancel" @onSubmit="formSubmit" />
    <v-row class="margin-fix pa-3">
      <h3>Comissões por modalidade de pagamento</h3>
    </v-row>
    <v-row class="margin-fix pa-3">
      <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
        <TextFieldInput
          :disabled="!!form.id"
          inputType="number"
          label-width="40%"
          v-model="form.otherCardPercentage"
          label="Cartões bandeirados"
          placeHolder="Digite o percentual adicional"
          :validationRules="[validationRules.otherCardPercentage]"
          @focusout="validate('otherCardPercentage')"
        />
      </v-col>
      <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
        <TextFieldInput
          :disabled="!!form.id"
          inputType="number"
          paddingLeft="5%"
          label-width="20%"
          v-model="form.koerichCardPercentage"
          label="Cartão Koerich"
          placeHolder="Digite o percentual adicional"
          :validationRules="[validationRules.koerichCardPercentage]"
          @focusout="validate('koerichCardPercentage')"
        />
      </v-col>
    </v-row>
    <v-row class="margin-fix pa-3">
      <h3>Liberações de pagamentos à vista das parcelas</h3>
    </v-row>
    <v-row class="margin-fix pa-3 pb-0">
      <v-col xl="5" lg="5" sm="12" md="5" class="pb-0">
        <SwitchInput
          :disabled="!!form.id"
          label-width="70%"
          v-model="form.immediatePaymentOtherCard"
          label="Permitir pagamentos à vista de cartões bandeirados"
        />
      </v-col>
      <v-col xl="5" lg="5" sm="12" md="5" class="pb-0">
        <SwitchInput
          :disabled="!!form.id"
          label-width="70%"
          v-model="form.immediatePaymentKoerichCard"
          label="Permitir pagamentos à vista do cartão Koerich"
        />
      </v-col>
    </v-row>
  </FormContent>
</template>

<script>
import FormContent from '@/components/template/form/FormContent';
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import SwitchInput from '@/components/template/form/input/SwitchInput';
import CategoriesForm from './CategoriesForm';

export default {
  name: 'CommissionsForm',
  components: {
    FormContent,
    TextFieldInput,
    SwitchInput,
    CategoriesForm,
  },
  props: {
    title: { type: String, required: false, default: 'COMISSÕES' },
    form: { type: Object, default: () => ({}) },
    validationRules: { type: Object, default: () => ({}) },
  },
  data: () => ({
    selected: false,
    header: [
      { text: 'Código', value: 'categoryId' },
      { text: 'Nome', value: 'name' },
      { text: 'Tarifa de venda', value: 'percentage' },
      { text: 'Ações', value: 'actions' },
    ],
    formDialog: false,
    categoryForm: {
      categoryId: null,
      name: '',
      percentage: 0,
    },
    editingCategory: false,
    editedCategoryIndex: null,
  }),
  methods: {
    validate(field) {
      this.$emit('validateRequired', this.$options.name, field, this.form[field]);
    },
    addCategoty() {
      this.editingCategory = false;
      this.formDialog = true;
    },
    editCategory(value, index) {
      this.editedCategoryIndex = index;
      this.categoryForm = value;
      this.editingCategory = true;
      this.formDialog = true;
    },
    deleteCategory(index) {
      this.$emit('deleteCategory', index);
    },
    formCancel() {
      this.formDialog = false;
    },
    formSubmit(categoryForm) {
      if (this.editingCategory) {
      // eslint-disable-next-line no-unused-expressions
      this.form?.percentageByCategory?.splice(this.editedCategoryIndex, 1, categoryForm);
      } else this.form.percentageByCategory.push({ ...categoryForm });
      this.formDialog = false;
      this.categoryForm.categoryId = null;
      this.categoryForm.name = '';
      this.categoryForm.percentage = 0;
    },
  },
};
</script>
