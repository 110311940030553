<template>
  <FormContent :selected="selected" class="pa-6">
    <v-row class="margin-fix pa-3">
      <h3>Informações gerais</h3>
    </v-row>
    <v-row class="margin-fix pa-3">
      <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
        <TextFieldInput
          class="custom-input-width-code"
          :disabled="true"
          v-model="form.id"
          label="Código"
          placeHolder="Código da regra de comissão"
          input-type="text"
          labelWidth="20%"
          :labelHAlign="colLabelHAlign"
        />
      </v-col>
    </v-row>
    <v-row class="margin-fix pa-3">
      <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
        <TextFieldInput
          :disabled="!!form.id"
          v-model="form.titleRule"
          label="Título da regra de comissão*"
          placeHolder="Informe o título da regra de comissão"
          input-type="text"
          labelWidth="20%"
          :labelHAlign="colLabelHAlign"
          :validationRules="[validationRules.titleRule]"
          @focusout="validate('titleRule')"
        />
      </v-col>
    </v-row>
    <v-row class="margin-fix pa-3">
      <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
        <TextAreaInput
          :disabled="!!form.id"
          v-model="form.description"
          label="Descrição"
          placeHolder="Informe a descrição da regra (opcional)"
          labelWidth="20%"
          :labelHAlign="colLabelHAlign"
        />
      </v-col>
    </v-row>
    <v-row class="margin-fix pa-3">
      <h3>Período da regra de comissão</h3>
    </v-row>
    <v-row class="margin-fix pa-3 pb-0">
      <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
        <TextFieldInput
          :class="customInputWidthDate"
          label-width="20%"
          :disabled="!!form.id"
          v-model="form.initialDateRule"
          input-type="datetime-local"
          label="Data inicial*"
          :validationRules="[validationRules.initialDateRule]"
          @focusout="validate('initialDateRule')"
        />
      </v-col>
      <v-col xl="6" lg="6" sm="12" md="5" class="pb-0">
        <TextFieldInput
          :class="customInputWidthDate"
          :disabled="isDisabled"
          paddingLeft="5%"
          label-width="20%"
          v-model="form.finalDateRule"
          input-type="datetime-local"
          label="Data final*"
          :validationRules="[validationRules.finalDateRule]"
          @focusout="validate('finalDateRule')"
        />
      </v-col>
    </v-row>
  </FormContent>
</template>

<script>
import FormContent from '@/components/template/form/FormContent';
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import TextAreaInput from '@/components/template/form/input/TextAreaInput';

export default {
  name: 'InformationPeriodForm',
  components: {
    FormContent,
    TextFieldInput,
    TextAreaInput,
  },
  props: {
    validationRules: { type: Object, default: () => ({}) },
    title: { type: String, required: false, default: 'INFORMAÇÕES E PERÍODO' },
    form: { type: Object, default: () => ({}) },
  },
  computed: {
    customInputWidthDate() {
      if (this.$vuetify.breakpoint.name === 'lg') {
        return 'custom-input-width-date';
      }
      return '';
    },
  },
  mounted() {
    this.isDisabled = !this.$store?.getters?.isAdmin;
  },
  data: () => ({
    selected: false,
    colLabelHAlign: 'left',
    editing: false,
    isDisabled: true,
  }),
  methods: {
    validate(field) {
      this.$emit('validateRequired', this.$options.name, field, this.form[field]);
    },
  },
};
</script>

<style lang="scss">
.custom-input-width-date {
  .v-input {
    max-width: 80%;
  }
}

.custom-input-width-code {
  .v-input {
    max-width: 40%;
  }
}
</style>
